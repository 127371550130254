import React from "react";

const ContactPage = () => {
  return (
    <div className="w-full min-h-screen h-full py-20 bg-gray-900 text-white flex flex-col items-center space-y-10">
      <h2 className="text-4xl font-semibold text-center">Contact Us</h2>
      <p className="text-lg text-center max-w-md">
        We’re here to help. Reach out to us anytime!
      </p>
      <div className="flex flex-col items-center space-y-4">
        <div className="text-xl flex items-center space-x-2">
          <strong>Email:</strong>
          <a
            href="mailto:support@gethomesafe.com"
            className="hover:underline text-blue-400"
          >
            support@gethomesafe.io
          </a>
        </div>
        {/* <div className="text-xl flex items-center space-x-2">
          <strong>Phone:</strong>
          <a href="tel:+447832602174" className="hover:underline text-blue-400">
            +4 (234) 567-890
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default ContactPage;

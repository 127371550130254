import firebase from "firebase/compat/app";
import { realTimeDB } from "../firebase/config";

// Function to update the counter and track the referrer in Realtime Database
export const markDownloadClicked = (platform, ui_source = "none") => {
  const counterRef = realTimeDB.ref(`download_${platform}`); // Reference to the 'from' counter in the database
  const newEntryRef = counterRef.push(); // Create a new entry in the 'from' counter

  const from = localStorage.getItem("referrer");

  newEntryRef
    .set({
      from: from || "none",
      ui_source: ui_source,
      timestamp: firebase.database.ServerValue.TIMESTAMP,
    })
    .then(() => {
      console.log("Path and referrer tracked successfully.");
    })
    .catch((error) => {
      console.error("Error tracking path and referrer:", error);
    });
};
